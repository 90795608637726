import { ErpTableOptions } from '@/types/type';
import { loadPage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 140,
      value: '',
      type: 'input',
      component: defineComponent({
        template: `
        <div :class="row.businessContractNo?'record__link':''"
        @click='link(row)'
        >{{filterName(row.businessContractNo)}}</div>
        `,
        props: ['row'],
        setup() {
          const link = (row) => {
            loadPage('OrderDetail', {
              orderId: row.orderId,
              version: row.version,
              name: 'AutoCertList',
            });
          };
          return { link };
        },
      }),
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 140,
      type: 'input',
      value: '',
    },
    {
      label: '柜号',
      prop: 'containerNo',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 120,

      type: 'input',
      value: '',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 120,

      type: 'input',
      value: '',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '口岸',
      prop: 'portName',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '品名',
      prop: 'nameCn',
      propDesc: 'nameCnMerged',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '币种',
      prop: 'tradeCurrency',
      propDesc: 'tradeCurrencyDisplay',
      minWidth: 120,
      type: 'multiSelect',
      options: 'trade_currency',
      value: [],
    },
    {
      label: 'HS编码',
      prop: 'hsCode',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '实际净重(kg)',
      prop: 'actualWeightSumDisplay',
      minWidth: 140,
      type: 'number',
      filterProp: ['actualWeightMinVal', 'actualWeightMaxVal'],
      value: [],
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',
      minWidth: 120,
      type: 'time',
      filterProp: ['shippingEndStartDate', 'shippingEndEndDate'],
      value: [],
    },
    {
      label: '送货日期',
      prop: 'deliveryDate',
      minWidth: 120,
      type: 'time',
      filterProp: ['deliveryStartDate', 'deliveryEndDate'],
      value: [],
    },
    {
      label: '自动证号',
      prop: 'autoCertNo',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '自动证单价',
      prop: 'unitPriceDisplay',
      minWidth: 140,
      type: 'number',

      filterProp: ['unitPriceMinVal', 'unitPriceMaxVal'],
      value: [],
    },
    {
      label: '重量(kg)',
      prop: 'weightDisplay',
      minWidth: 120,
      type: 'number',
      filterProp: ['weightMinVal', 'weightMaxVal'],
      value: [],
    },
    {
      label: '有效期',
      prop: 'validDate',
      minWidth: 120,
      type: 'time',
      filterProp: ['validStartDate', 'validEndDate'],
      value: [],
    },
    {
      label: '核销状态',
      prop: 'writtenOffStatus',
      propDesc: 'isWrittenOffDisplay', // 需要转换的列值的属性名
      minWidth: 120,
      type: 'multiSelect',
      value: [1, 2],
      options: [
        {
          value: 1,
          label: '空',
        },
        {
          value: 2,
          label: '未核销',
        },
        {
          value: 3,
          label: '已核销',
        },
      ],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain @click="add(row)"
        v-if="hasPermission('erp:order:saveAutoCert') && row.autoCertNo == '' || hasPermission('erp:order:saveAutoCert')&&row.autoCertNo == null"
        >新增</el-button>
        `,
        props: ['row'],
        setup() {
          const add = (row) => {
            Instance.setupState.addAutoCert(row);
          };
          return { add };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
