<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">
        {{ formType === 1 ? '新增自动证' : '编辑自动证' }}
      </div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
      <div class="mali-edit__form">
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item label="自动证号" prop="autoCertNo">
              <el-input
                :maxlength="10"
                v-model="formData.autoCertNo"
                clearable
                @blur="changeAutoCertNo($event)"
                placeholder="请输入自动证号"
                class="mali-full__inputcom"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="自动证单价" prop="unitPrice">
              <el-input
                :maxlength="6"
                v-model="formData.unitPrice"
                clearable
                @blur="changeDecimal($event, 'unitPrice', 1000, 3)"
                placeholder="请输入自动证单价"
                class="mali-full__inputcom"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重量(kg)" prop="weight">
              <el-input
                :maxlength="9"
                v-model="formData.weight"
                clearable
                @blur="changeDecimal($event, 'weight', 100000, 5)"
                placeholder="请输入重量(kg)"
                class="mali-full__inputcom"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker v-model="formData.validDate" type="date" placeholder="请选择有效期" prefix-icon=" " style="width: 100%"> </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="核销状态" prop="isWrittenOff">
              <el-select v-model="formData.isWrittenOff" class="mali-full__input" placeholder="请选择核销状态">
                <el-option v-for="item in writtenOffList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
import { httpPost } from '@/api';
import { formatTime } from '@/utils/publicMethods';
import { toRaw } from 'vue';
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      writtenOffList: [
        {
          value: 0,
          label: '未核销',
        },
        {
          value: 1,
          label: '已核销',
        },
      ],
      formData: {
        // 绑定表单数据
        autoCertNo: null,
        hsCode: null,
        id: null,
        isWrittenOff: 0,
        orderId: null,
        unitPrice: null,
        validDate: null,
        version: null,
        weight: null,
      },
      rules: {
        // 检验规则
        autoCertNo: [
          {
            required: true,
            message: '请输入自动证号',
            trigger: 'change',
          },
          {
            len: 10,
            message: '固定长度为10',
            trigger: 'blur',
          },
        ],
        isWrittenOff: [
          {
            required: true,
            message: '请选择核销状态',
            trigger: 'change',
          },
        ],
      },
    };
  },
  created() {
    const data = toRaw(this.editData);
    this.formData.orderId = data.orderId;
    this.formData.hsCode = data.hsCode;
    if (this.formType !== 1) {
      this.formData = JSON.parse(JSON.stringify(data));
      for (const item in this.formData) {
        if (this.formData[item] === '-') {
          // 如果编辑状态值为“-” 则转换成‘’
          this.formData[item] = '';
        }
      }
      if (this.formData.isWrittenOff) {
        this.formData.isWrittenOff = 1;
      } else {
        this.formData.isWrittenOff = 0;
      }
    }
  },
  methods: {
    changeAutoCertNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
      this.formData.autoCertNo = value;
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     * @param dot 截取位数
     */
    changeDecimal(e, name, len, dot) {
      let value = e.target.value;
      // 格式化汇率格式
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      if (name === 'weight') {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      } else {
        value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      }

      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot);
        }
      }
      // 只能输入两个小数
      this.formData[name] = value;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    async saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.validDate = formatTime(params.validDate, 'YYYY-MM-DD');
      const res = await httpPost('/malicrm/order/saveAutoCert', params);
      if (res.code === 200) {
        this.successTip('保存成功');
        this.$emit('cancel', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
      } else {
        this.errorTip(res.message);
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
