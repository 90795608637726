import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_AutoCertForm = _resolveComponent("AutoCertForm")!
  const _component_AutoCertDetail = _resolveComponent("AutoCertDetail")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/order/queryAutoCertListWithPage",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "autocertlist",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => []),
      _: 1
    }, 8, ["columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      erp: "",
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeVisible())),
      class: "fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.formType !== 3)
          ? (_openBlock(), _createBlock(_component_AutoCertForm, {
              key: 0,
              onCancel: _ctx.cancel,
              editData: _ctx.data.editData,
              formType: _ctx.formType
            }, null, 8, ["onCancel", "editData", "formType"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 3)
          ? (_openBlock(), _createBlock(_component_AutoCertDetail, {
              key: 1,
              formType: 3,
              onCancel: _ctx.cancel,
              editData: _ctx.data.editData,
              onEdit: _ctx.editAutoCert
            }, null, 8, ["onCancel", "editData", "onEdit"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}