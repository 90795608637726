
import { defineComponent, getCurrentInstance, reactive, ref, toRaw } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import AutoCertForm from './components/Form.vue';
import AutoCertDetail from './components/AutoDetail.vue';
export default defineComponent({
  name: 'AutoCertList',
  components: { ErpList, AutoCertForm, AutoCertDetail },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { visible: detailVisible, showVisible: showDetailVisible, closeVisible: closeDetailVisible } = useVisible();
    const data = reactive({
      editData: null,
    });
    const customParams: any = ref();
    const formType = ref(1);
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeDetailVisible();
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 新增上游合同
    const addAutoCert = (row) => {
      formType.value = 1;
      data.editData = toRaw(row);
      showVisible();
    };
    const editAutoCert = () => {
      formType.value = 2;
      showVisible();
    };
    // 查看详情
    const rowClick = (row, column) => {
      const { label } = column;
      if (label !== '销售合同号' && label !== '操作') {
        data.editData = toRaw(row);
        formType.value = 3;
        showVisible();
      }
    };

    return {
      addAutoCert,
      formType,
      editAutoCert,
      detailVisible,
      showDetailVisible,
      closeDetailVisible,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      data,
    };
  },
});
